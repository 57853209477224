import { environment } from './../environments/environment';

export const appApiResources = {
  baseUrl: environment.baseUrl,
  memberOnBoardingBV: 'memberOnboarding/BV/{membershipNumber}',
  verifyEmail: 'verifyEmail',
  verifyMobile: 'verifyMobile',
  appInit: 'config/appInit',
  accountActivityUrl: 'account/activation',
  resendOtpUrl: 'account/resendOTP',
  requestOtpUrl: 'account/sendOTP',
  verifyOtpUrl: 'account/validateOTP',
  acountActivateProfileUrl: 'account/activation',
  loginUrl: 'account/login',
  reservationsUrl: 'reservations/',
  recrodUrl: 'reservations?status=Redeemed',
  recrodSearchUrl: 'reservations?status=AR',
  contryUrl: 'countries',
  reservationStatusUpdateUrl: 'reservations/reservationStatusUpdate',
  uploadRecordExceptionUrl: 'uploadRecordException',
  IPReservationsUrl: 'IPReservations',
  accountUrl: 'account',
  reservationsmodifyReservationUrl: 'reservations/modifyReservation/',
  settleChequeUrl: 'settleCheque',
  addProspect: 'addProspect/',
  // memberProfile: 'memberha/profile/',
  memberProfile: 'member/profile/',
  memberReviews: 'memberReviews/profile/',
  memberTransactions: 'memberTransactions/profile/',
  resendForgotOtpUrl: 'resendOTP',
  requestForgotOtpUrl: 'sendOTP',
  verifyForgotOtpUrl: 'validateOTP',
  changePassword: 'members/changePassword',
  resetPassword: 'members/resetPassword',
  FeedbackCount: 'outletFeedbackCount/',
  redeemTransaction: 'redeemTransaction/',
  // redemptionEmail: '',
  topspender: 'topspenders',
  redemptionEmail: 'sendreport',
  openNewReservation: 'members',
  membership: 'membership/',
  redemptionlogsUrl: 'redemptionlogs',
  reservationwalkinUrl: 'reservation/walkin',
  // reservationwalkinUrl: 'reservationgjkfy',

  feedbackRespond: 'feedbackResponse',
  redeemcancelUrl: 'redeem/cancel',
  applicationORjson: 'application/json',
  aemUrl: environment.aem_base_url,
  outletList: 'outletlist',
  promocodeForReservation: 'validatepromocodereservation',
  detailApi: "details",
  createReservation: "reservation",
  outLetOpenrestrictions: "outLetOpenrestrictions",
  reassign:"reallocateTable",
  assignTable:"allocateTable",
  openTableReservation : "openTableReservation",
  outletSection : "propertySections",
  outletTags : "outletTags",
  resCancellationPeriod : "resCancellationPeriod",
  TableSendNotification: "sendNotification",
  apiAICALL:"https://api.edenai.run/v2/image/object_detection"
}

export const storageKeys = {
  pwaId : 'pwaId',
  appInitData: 'app_init_data',
  appComponent: 'appComponent',
  employeeId: 'employee_ID',
  devicCheckBase64: 'devicCheckBase64',
  userDetails: 'user_details',
  userPageAccess: 'hotlUserAccess',
  sessionToken: 'sessionToken',
  loginCheck: 'isLogined',
  daysCalculation: 86400000,
  somethingWentWrong: 'Something Went Wrong',
  userDetailsForChat: 'userDetailsForChat',
  authenticatekey: 'authenticatekey',
  csrftoken: 'csrftoken',
  anonymousKey: 'anonymousKey',
  employeeidHeader: 'employeeidHeader',
  TestLogin: 'TestLogin',
  aemData: 'aemData',
  selecOutlet: 'selecOutlet',
  userPreferredLanguage: 'userPreferredLanguage',
  welcomeButtonStatus: 'welcomeButtonStatus',
  saveauthenticatekey:'saveauthenticatekey',
  saveanonymousKey:'saveanonymousKey'


}

export const constActivtePage = {
  statusCode: '200',
  emailKey: 'email',
}
export const constantDashboard = {
  awaitingArrival: "AA",
  awaitingConfirmation: "AC",
  inProgress: "IP",
  viewReservation: 'AR',
  walkinIn: 'Redeemed'
}

export const constLoginPage = {
  loginTypeEmail: 'EmailLogin',
  loginTypeMobile: 'NumberLogin',
  loginTypeOTP: 'LoginOTP'
}

export const WALK_IN_PAGE = {
  Walk: 'Components.Walk',
  phoneNumber: 'phoneNumber',
  emailaddress: 'emailaddress',
  India: "India",
  firstName: 'firstName',
  email: 'email',
  mobile: 'mobile',
  numberofGuest: 'numberofGuest',
  memshipNo: 'memshipNo',
  countryCodeSelected: 'countryCodeSelected',
  customModal: 'customModal',
}

export const constDateCloseChequePage = {
  /* month ahead (3,600,000 * 24 * 30 ) */
  oneMonthAhead: '2592000000',
  extendDate: '2629800000'
}
export const constDateViewReservationPage = {
  /* month ahead (3,600,000 * 24 * 30 ) */
  oneMonthAhead: '2592000000',
  extendDate: '2629800000'
}
export const constDateFeedbackPage = {
  /* month ahead (3,600,000 * 24 * 30 ) */
  oneMonthAhead: '2592000000',
  extendDate: '2629800000'
}

export const constManageReservationPage = {
  confirmed: 'Confirmed',
  noShow: 'Cancelled',
  regret: 'Regret'
}
export const HOURS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

export const MINUTES = [
  '00',
  '15',
  '30',
  '45',
];
export const MINUTESTwo = [
  '00',
  '15',
  '30',
  '45',
];

export const ReservationCelebrations = {
  appBirthday: 'Birthday',
  appAnniversary: 'Anniversary',
  appOther: 'Other',
}

export const TIME_24H = {
  AM: 'AM',
  PM: 'PM',
  COUNT_ADD: ':',
}


export const TITLE = {
  TITLE: 'title',
}

export const AEM_DETAILS = {
  CERTIFICATE: 'certificate',
}

export const COUNT = {
  COUNT_00: '00',
  COUNT_0: '0',
  COUNT_1: '1',
  COUNT_2: '2',
  COUNT_3: '3',
  COUNT_4: '4',
  COUNT_5: '5',
  COUNT_6: '6',
  COUNT_7: '7',
  COUNT_8: '8',
  COUNT_9: '9',
  COUNT_10: '10',
  COUNT_11: '11',
  COUNT_12: '12',
  COUNT_13: '13',
  COUNT_14: '14',
  COUNT_15: '15',
  COUNT_16: '16',
  COUNT_17: '17',
  COUNT_18: '18',
  COUNT_19: '19',
  COUNT_20: '20',
  COUNT_24: '24',


  COUNT_51: '51',
  COUNT_59: '59',

}

export const COUNT_NUMBER = {
  COUNT_0: 0,
  COUNT_1: 1,
  COUNT_2: 2,
  COUNT_3: 3,
  COUNT_4: 4,
  COUNT_5: 5,
  COUNT_6: 6,
  COUNT_7: 7,
  COUNT_8: 8,
  COUNT_9: 9,
  COUNT_10: 10,
  COUNT_11: 11,
  COUNT_12: 12,
  COUNT_13: 13,
  COUNT_14: 14,
  COUNT_15: 15,
  COUNT_16: 16,
  COUNT_17: 17,
  COUNT_18: 18,
  COUNT_19: 19,
  COUNT_20: 20,
  COUNT_24: 24,
  COUNT_30: 30,
  COUNT_31: 31,


  COUNT_99: 99,

}


export const COLORCODE = {
  DEFAULT_CODE: '0123456789ABCDEF',
}

export const SPENDERS_PAGE = {
  Spenders: 'Components.Spenders',
  mtd: 'mtd',
  BLANCK: "",
  memberShipModal: 'memberShipModal',
  imageUrl: 'imageUrl',
  MTD: "MTD",
  MTDPASS: 'MTD',
  Report_Dashboard: 'Components.Report_Dashboard',
  Dashboard: "Dashboard",
  YTD: 'YTD',
  highchartsSpends: 'highchartsSpends',
  column: 'column',
  Spends: 'Components.Spends',
  ddb641: '#ddb641',
  d51b32: '#d51b32',
  d5d5d5: '#d5d5d5',
  CurrentYear: 'Components.CurrentYear',
  LastYear: 'Components.LastYear',
  highchartsCover: 'highchartsCover',
  Cover: 'Components.Cover',
  highchartsApc: 'highchartsApc',
  APC: 'Components.APC',
  highchartsSpendsByMonth: 'highchartsSpendsByMonth',
  highchartsCoverByMonth: 'highchartsCoverByMonth',
  Covers: 'Components.Covers',
  highchartsApcByMonth: 'highchartsApcByMonth',
  highchartsByDw: 'highchartsByDw',
  Spends_Week: 'Components.Spends_Week',


}

export const defaultValues = {
  startTimeHour: 1,
  startTimeMinute: 0,
  startTimePeriod: 'AM',
  endTimeHour: 1,
  endTimeMinute: 0,
  endTimePeriod: 'AM',
  feeMinutes: 10,
  feeLimit: 6,
  locationLimit: 20,
  itemDescriptionLimit: 150,
  uniqueId: ''
}


export const JAVASCRIPT = {
  JAVASCRIPT_HAS: '#',
  NONE: 'none',
  ITEM: '-item',
  CLASS: 'class',
  ACTIVE: 'active',
  BLANK: '',
  SHOW: 'show',
  HIDE: 'hide',
  T: 'T',
  STYLE: 'style',
  DISPLAY_NONE: 'display: none;',
  FALSE: 'false',
  TRUE: 'true',
  BLOCK: 'block',
  DOWN: 'down',
  UP: 'up',
  SPACE: ' ',
  SIZE100: '100%',
  BLANKSPACE: " ",
  BLANKSPACESINGLE: ' ',
  UNDERSPACE: "-",
  BLANKDOUBLE: "",
  YES: 'Yes',
  FORWARDSLASH: '/',
  UNDERFINED: undefined,
  SPITSPACE: '-',


}



export const REGEX = {
  email: /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i,
  password: /^(?=.{8,12})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-*!_/?:+=]).*$/,
  firstName: /^[a-zA-Z ]{2,20}$/,
  lastName: /^[a-zA-Z ]{2,20}$/,
  onlyNumberValidate: /[^0-9]+/ig,
  alphaNumericValidation: /[a-z,.'-]/gi,
  promocode: "^[a-zA-Z0-9]{4,12}$",
  amountValidate: "[^\d{0,9}$|(?=^.{0,9}$)^\d+\.\d{0,2}$",
  pincodeCheck:"^[0-9]{6}$",
  onlyNumberValidateTable: /[1-9]+/ig,
}

export const EDIT_EMAIL_MOBILE_MODAL = {
  EMAIL: "email",
  MOBILE: "mobile",
  EDIT_PROFILE: "editprofile",
  VALUE_EDIT_DETAILS: "ValueEditDetails",
  DETAILS_EMP: "detailsEmployee",
  COUNTRY_CODE: "countryCode",
  COUNTRY_CODE_SELECTED: "countryCodeSelected"
}

export const RESERVATION_DETAILS_MODAL = {
  RESTAURANT: "restaurant",
  GUEST: "guest",
  POOL: "pool",
  SPA: "Spa",
  CHILD: "child",
  ADULT: "adult",
  reservation_details_send: "reservationDetailsSend",
  reservationDetailsModal: 'reservationDetailsModal',
  reservationDetailsDateTime: 'reservationDetailsDateTime',
}

export const RESPONDMODEL_MODAL = {
  COUNT_99: 99,
  COUNT_0: 0
}


export const RESERVATION_REGRET_MODAL = {
  RESERVATION_SFID: "reservationSfid"
}


export const FAQs_PAGE = {
  SELECTED: 'Components.SELECTED',
  FAQs: 'Components.FAQs',
  FAQ: 'faq'
}

export const SETTINGS_PAGE = {
  SETTINGS: 'Components.SETTINGS',
  FOCUSABLE_CLOSE: 'item-label item ios item-lines-none ion-focusable hydrated',
  FOCUSABLE_OPEN: 'open item-label item ios item-lines-none ion-focusable hydrated',
  PUT_ACCOUNT_SETTINGS: 'PUT_ACCOUNT_SETTINGS',
}

export const RESERVATION_MASTER_PAGE = {
  RESERVATION_MASTER: 'Components.RESERVATION_MASTER',
  day: 'day',
  selectedAllDays: [0, 1, 2, 3, 4, 5, 6],
  COUNT_00: ':00',
  COUNT_ADD: ':',
  COUNT_TIME: '12:00',
  ionSelect: 'ion-select',
  selectIconInner: '.select-icon-inner',
  COUNT: 'COUNT',
  BLOCK: 'BLOCK',
  closedReservationWindow: "closedReservationWindow",
  endDate: 'endDate',
  startDate: 'startDate'
}

export const activityTypes = {
  certificatePurchased: 'Certificate Purchased',
  certificateGifted: 'Certificate Gifted',
  pointsPurchased: 'Points Purchased',
  pointsEarned: 'Points Earned',
  membershipPurchased: 'Membership Purchased',
  membershipUpgraded: 'Membership Upgraded',
  membershipRenewed: 'Membership Renewed',
  membershipGifted: 'Membership Gifted',
  membershipReactivated: 'Membership Reactivated',
  referralBenefit: 'Referral Benefit',
  transaction: 'Redemption at',
  cancelReservation: 'Cancel Reservation',
  pointsRedeemed: 'Points Redeemed',
  feedback: 'Feedback',
  profileUpdate: 'Profile Updated',
  viewCheque: 'View Cheque',
  membershipLapsed: 'Membership Lapsed',
  lapsedCertificate: 'Lapsed Certificate',
  giftReceived: "Gift Received",
  signup: "Signup",
  spouseMembershipAdded:"Spouse Membership Added"
};

export const OFFER_EVENT_PAGE = {
  OFFER_EVENT: 'Components.OFFER_EVENT',
  OFFER_DETAILS: 'Components.OFFER_DETAILS',
  PROMTION: 'promotioncontent',
}

export const REDEMPTION_LOG_PAGE = {
  REDEMPTION_LOG: 'Components.REDEMPTION_LOG',
  TODAY: 'Today',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  CUSTOM: 'Custom',
  DISCOUNT: 'Discount',
  CERTIFICATE: 'Certificate',
  BENEFITS: 'benefits',
  MAIL_REPORT_MODAL: 'mailReportModal',
  REDEMPTION_TYPE: 'redemptionLog',
  USER_TYPE: 'member',
  USER_TYPE_SELECTED: 'Member',
  Redemption: "Redemption",
}

export const VIEW_RESERVATIONS_PAGE = {
  VIEW_RESERVATIONS: 'Components.VIEW_RESERVATIONS',
  TODAY: 'Today',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  CUSTOM: 'Custom',
  VIEW_RESERVATIONS_TYPE: 'ViewReservations',
  FALSE: 'false',
  Reservation: "Reservation",
  Cancelled: "Cancelled",
  Redeemed: "Redeemed",
  Close_Cheque: "Close Cheque",
  Regret: "Regret"
}

export const PHONECOUNTRY = {
  COUNTRY: 'India',
  SHORTCODE: 'in',
  DIALCODE: '91'

}

export const FEEDBACK_HISTORY_PAGE = {
  FEEDBACK_HISTORY: 'Components.FEEDBACK_HISTORY',
  TODAY: 'Today',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  CUSTOM: 'Custom',
  FEEDBACK_TYPE: 'feedbackHistory',
  GET_FEEDBACK_COUNT: 'GET_FEEDBACK_COUNT',
  feedbackDetails: 'feedbackDetails'

}

export const ACTIVATE_RESERVATION_PAGE = {
  ACTIVATE_RESERVATION: 'Components.ACTIVATE_RESERVATION',
  ionSelect: 'ion-select',
  selectIconInner: '.select-icon-inner',
  genderCode: 'genderCode',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  mobile: 'mobile',
  countryCodeSelected: 'countryCodeSelected',
  promocode:'promocode',
  selectedOutlet:'selectedOutlet',
  chequeNumber:'chequeNumber',
  tableNumber:'tableNumber',
  hotelComment:'hotelComment',
  promocodeDetails:'promocodeDetails'
}

export const RESERVATION_LIST_PAGE = {
  RESERVATION_LIST: 'Components.RESERVATION_LIST',
}


export const SET_DATE_MODAL = {
  SET_DATE: 'set-date',
  TODAY: 'Today',
  FEEDBACK_TYPE: 'feedbackHistory',
  VIEW_RESERVATIONS_TYPE: 'ViewReservations',
  REDEMPTION_TYPE: 'redemptionLog',
  STAET_DATE: 'startDate',
  END_DATE: 'endDate',
  GET_TYPE_PARAMS: 'dataArr',
  WEEKLY_NUMBER: '518400000',
  CUSTOM: 'Custom',
  customMonth: 'customMonths.customMonth',
}

export const USER_TYPE_MODAL = {
  GET_TYPE_PARAMS: 'dataArr',
}
export const EDITPROFILE_MODAL = {
  EMAIL: 'email',
  MOBILE: 'mobile',
}

export const ALLOW_OTP = {
  close: 'close',
  deny: 'deny',
  allow: 'allow',
}

export const MANAGE_RESERVATION_PAGE = {
  MANAGE_RESERVATION_TITLE: 'Components.MANAGE_RESERVATION_TITLE',
  MANAGE_RESERVATION: 'manageReservation',
  MODIFY: 'modify',
  REGRET: 'regret',
  MESSAGE: 'You can modify only after call with customer',
  CANCEL: 'Cancel',
  CONTINUE: 'Continue',
  AA: 'AA',
  AC: 'AC',
  modifyModal: 'modifyModal',
  Certificate: 'Certificate',
  manageReservation: 'manageReservation',
  In_Progress: 'In Progress',
  regrettedModal: 'regrettedModal',
  editReservation: 'editReservation',
  confirmationModal: 'confirmationModal',
  noShowModal: 'noShowModal',
  closeCheque: 'closeCheque',
  cancelCloseCheque: 'cancelCloseCheque',
  GET_REDEEM_TRXN: 'GET_REDEEM_TRXN',
  Redeemed: 'Redeemed',
}

export const QUERY_PAGE = {
  CHATMODAL: 'chatMudal',
  TEXT: 'text',
  NAME: 'Name',
  MOBILE: 'Mobile',
  EMAIL: 'Email',
  queryKind: 'queryKind',
  show: "show",
  chat: 'chat'
}

export const CHAT = {
  navbarBackground: "#FAFAFA",
  navbarInverted: "#010101",
  brandPrimary: "#C99700",
  brandSecondary: "#C99700",
  brandPrimaryInverted: "#FBFBFB",
  brandSecondaryInverted: "#FCFCFC",
  contrastPrimary: "#000000",
  contrastSecondary: "#767676",
  contrastTertiary: "#BABABA",
  contrastQuaternary: "#F1F1F1",
  contrastInverted: "#FFFFFF",
  feedbackPrimary: "#E74C3C",
  feedbackSecondary: "#2ECC71",
  feedbackTertiary: "#F5A623",
  overlay: "#000000",
  text: 'text',
  Name: 'Name',
  Mobile: 'Mobile',
  Email: 'Email',
  hidden: 'hidden',
  Customer_Name: 'Customer Name',
  Customer_Mobile: 'Customer Mobile',
  Account: "Account",
  AccountId: "AccountId",
  Customer_name: "Name",
  customer_name: "Customer Name",
  PersonMobilePhone: "PersonMobilePhone",
  customer_mobile: "Customer Mobile",
  Contact: "Contact",
  ContactId: "ContactId",
  MobilePhone: "MobilePhone",
}

export const OUTLET_DETAILS_PAGE = {
  OUTLET_DETAILS: 'Components.OUTLET_DETAILS',
  OUTLET: "outlet"
}

export const LOCATIONSERVICE = {
  GPSMSG: 'ServiceMessages.GPSMSG',
  APPMSG: 'ServiceMessages.APPMSG',
  OUTLETMSG: 'ServiceMessages.OUTLETMSG',
  HOTELCHANGEMSG: 'ServiceMessages.HOTELCHANGEMSG',
  FIXEDDISTENCE: '90000000000000'
}

export const SSOSERVICE = {
  BLANK: '_blank',
  PROMTION: 'location=no,clearcache=yes,clearsessioncache=yes',
  HTML_TAG: "document.getElementsByTagName('html')[0].innerHTML"
}

export const RESERVATION_BLOCK = {
  RESERVATION_BLOCK: 'Components.RESERVATION_BLOCK',
}

export const RESERVATION_COUNT = {
  RESERVATION_COUNT: 'Components.RESERVATION_COUNT',
  letters: '0123456789ABCDEF',
}

export const welcomeScreenDefaultSlider = [

 
  {
    img: "assets/imgs/splash1.png",
    logoimg: "assets/imgs/club-marriott.png",
    description: ""
  },
  {
    img: "assets/imgs/splash2.png",
    logoimg: "assets/imgs/club-marriott.png",
    descrption: "Deliver Personalized and Delightful Experiences to Members",
  },
  {
    img: "assets/imgs/splash3.png",
    logoimg: "assets/imgs/club-marriott.png",
    description: ""
  }
];

export const CUSTOM_SELECT_ICON = {
  ionSelect: 'ion-select',
  selectIconInner: '.select-icon-inner',
  selectIcon: '.select-icon'
}

export const ADDCLUBPAGE = {
  // ADDCLUBPAGE_TITLE: "Add Club Marriott Prospect",
  AddModal: 'AddModal',
  ADDCLUBPAGE_TITLE: 'addClubPage.ADDCLUBPAGE_TITLE'
}

export const AVAILABLE_CERTIFICATES_PAGE = {
  AVAILABLE_CERTIFICATES: 'Components.AVAILABLE_CERTIFICATES',
  guestProfile: 'guestProfile',
  Restaurant: 'Restaurant',
  tableNoModal: 'tableNoModal',
  arrivalArrivedGuestModal: 'arrivalArrivedGuestModal'
}

export const AWAITING_ARRIVAL_PAGE = {
  AWAITING_ARRIVAL: 'Components.AWAITING_ARRIVAL',
  digit: '2-digit',
  employeeId: 'employeeId',
  tableNoModal: 'tableNoModal',
  arrivalArrivedGuestModal: 'arrivalArrivedGuestModal',
  Pool: 'Pool',
  Spa: 'Spa',
  arrivalArrived: 'arrivalArrived',
  customModal: 'customModal',
  arrivalArrivedGuestName: 'arrivalArrivedGuestName',
  mainCard: 'mainCard',
  CSS_200px: '200px',
  CSS_linear: 'max-height 0.2s linear',
  CSS_75px: '75px'
}

export const CARD_BENEFITS_PAGE = {
  CARD_BENEFITS: 'Components.CARD_BENEFITS',
  cardarrivalArrivedGuest: 'cardarrivalArrivedGuest',
  tableNoModal: 'tableNoModal',
  benefitRedeem: 'benefitRedeem',
  Restaurant: "Restaurant",
}

export const CERTIFICATE_DETAILS_PAGE = {
  CERTIFICATE_DETAILS: 'Components.CERTIFICATE_DETAILS',
  manageReservation: 'manageReservation',
}

export const CLOSE_CHEQUE_PAGE = {
  CLOSE_CHEQUE: 'Components.CLOSE_CHEQUE',
  setDate: "setDate",
  closeChequeId: 'closeChequeId',
  reservationDetails: 'reservationDetails',
}

export const GUEST_PROFILE_PAGE = {
  GUEST_PROFILE: 'Components.GUEST_PROFILE',
  ACTIVITIES: 'activities.',
  tel: 'tel:',
  mailto: 'mailto:',
  certificate: 'certificate',
  cardbenefit: 'cardbenefit',
  Handle: 'Handle',
  Certificates: 'Certificates',
  Benefits: "Benefits",
  Points: "Points",
  Preferences: "Preferences",
  memberReview: "memberReview",
  loyalty: 'loyalty',
  business: 'business',
  spouse: 'spouse',
  celebratory: 'celebratory',
  gst: 'gst',
  address: 'address',
  spouseMembershipAdded: "Spouse Membership Added",
}

export const HANDLE_WITH_CARE_PAGE = {
  HANDLE_WITH_CARE: 'Components.HANDLE_WITH_CARE',
  addInstance: "addInstance",
  mailto: 'mailto:',
  certificate: 'certificate',
  cardbenefit: 'cardbenefit',
}

export const IN_PROGRESE_PAGE = {
  IN_PROGRESE: 'Components.IN_PROGRESE',
  reservationDetails: 'reservationDetails',
  localizedType: "localizedType."
}

export const REVIEW_HISTORY_PAGE = {
  REVIEW_HISTORY: 'Components.REVIEW_HISTORY',
  reservationDetails: 'reservationDetails',
  months: 'months.',
}

export const MEMBER_SEARCH_PAGE = {
  MEMBER_SEARCH: 'Components.MEMBER_SEARCH',
  customModal: 'customModal',
  Restaurant: 'Restaurant',
  arrivalArrivedGuest: 'arrivalArrivedGuest',
}

export const PREFERENCE_PAGE = {
  PREFERENCE: 'Components.PREFERENCE',
}

export const RECORD_EXC_PAGE = {
  RECORD_EXC: 'Components.RECORD_EXC',
  exceptionReason: "exceptionReason",
  choose_Category: 'Please Choose Category',
  reservationDetails: 'reservationDetails',
  checkedValue: 'checkedValue',
}

export const REDEEM_BENEFITS_PAGE = {
  REDEEM_BENEFITS: 'Components.REDEEM_BENEFITS',
  resendOtp: 'resendOtp',
  mobile: 'mobile',
  restaurant: 'restaurant',
  guest: 'guest',
  pool: 'pool',
  child: 'child',
  adult: 'adult',
  resetRequest: true,
  redeemRequest: true,
  certifcateWarn: 'certifcateWarn',
  outletcateWarn: 'outletcateWarn',

}

export const OTP_COMPONENT = {
  otpAutoForReset: 'otpAutoForReset',
  onSMSArrive: 'onSMSArrive',
  data: 'data',
  address: 'address',
  allow: 'allow',
  show: "show",
  otp_MSG: "openReservation.otp_MSG",
}

export const BACK_BUTTON = {
  select: "select",
  show: "show",
  title: 'title',
}

export const FEEDBACK_STAR = {
  setRating: 'setRating',
}


export const REDEEMED_PAGE = {
  REDEEM_BENEFITS: "Redeem Benefits",
  Certificate_Redeemed: 'Components.Certificate_Redeemed',
  Benefit_Redeemed: 'Components.Benefit_Redeemed',
  Certificate: 'Certificate',
  certificate: 'certificate',
  card_Benefit: 'Card Benefit',
  cardbenefit: 'cardbenefit',
}

export const AWAITING_CONFIMATION_PAGE = {
  AWAITING_CONFIMATION: 'Components.AWAITING_CONFIMATION',
  reservationDetails: 'reservationDetails',
  reservationcreatedAt: 'reservationcreatedAt',
  memberDetails: 'memberDetails',
  photo: 'Photo',
}

export const DASHBOARD_PAGE = {
  sideMenuOpened: 'sideMenuOpened',
  confirmation: 'confirmation',
  awaiting: 'awaiting',
  progress: 'progress',
  close: 'close',
  addclub: 'addclub',
  notification: 'notification',
  offercheque: 'offercheque',
  guestSearch: 'guestSearch',
  Redemption: 'Redemption',
  FeedBackPage: 'FeedBackPage',
  outlet: 'outlet',
  orders: 'orders',
  allOrders:'allOrders',
  delivery:'delivery',
  takeaway:'takeaway',
  
}

export const EDIT_PROFILE_PAGE = {
  EDIT_PROFILE: 'Components.EDIT_PROFILE',
  changePasswordType: 'changePasswordType',
  star: '********',
  add: '+',
  new_ph_no: 'new_ph_no'
}

export const LOGIN_PAGE = {
  countryCodeSelected: 'countryCodeSelected',
  email: 'email',
  mobile: 'mobile',
  password: 'password',
  true: 'true',
  loggedUser: 'loggedUser',
  LOCATION_ONLY_LOGIN: 'LOCATION_ONLY_LOGIN',
  SelectCountryText: 'Components.SelectCountryText',
  loadstop: 'loadstop',
  div: "div",
}

export const APP_COMPONENT = {
  feedback: 'feedback',
  reservationList: 'reservationList',
  faq: 'faq',
  setting: 'setting',
  redemption: 'redemption',
  edit: 'edit',
  viewreservations: 'viewreservations',
  testSlide: 'testSlide',
  reservationsmaster: 'reservationsmaster',
  search: 'search',
  exception: 'exception',
  logout: 'logout',
  dashboard: 'dashboard',
  outletdetails: 'outletdetails',
  offersevents: 'offersevents',
  openReservation: 'openReservation',
  staticUUID: "staticUUIDFromIONIC-21201613332335",
  switchoutlet: "switchoutlet",
  outletMaster: "outletMaster",
  appTour: 'appTour',
  setupOutlet: 'setupOutlet',
  setupRules: 'setupRules',
  setupWidget: 'setupWidget',
  setupMenu: 'setupMenu',
  editMenu: 'editMenu',
  menu: 'menu',
  blockService: 'blockService',
  itemNotAvailable: 'itemNotAvailable',
  revenueManagement: 'revenueManagement',
  updateItem: 'updateItem',
}

export const PRIVACY_POLICY_PAGE = {
  mainCard: 'mainCard',
  max_height: 'max-height 0.2s linear',
  max_height_PX: '110px',
}

export const REDEMPTION_LIST_PAGE = {
  REDEMPTION_LIST: 'Components.REDEMPTION_LIST',
  customModal: 'customModal',
  Restaurant: "Restaurant",
  closeChequeId: 'closeChequeId',
  cancelCloseCheque: 'cancelCloseCheque',
  spa: "Spa",
  pool: "Pool"
}

export const RESET_PASSWORD_PAGE = {
  resetPage: 'resetPage',
  password: 'password',
  confirmPassword: 'confirmPassword',
  LOGIN: 'L',
  confirmationModal: 'confirmationModal',
  ERROR_TLC910: "TLC-910",
  ERROR_TLC944: "TLC-944",
  changePasswordType: 'changePasswordType',
  activate_account: 'activate-account',
}

export const SELECT_OUTLET_PAGE = {
  selectOutletPage: 'selectOutletPage',
  true: 'true',
  selectOutletCountry: 'selectOutletPage.country',
  selectOutletCity: 'selectOutletPage.city',
  selectOutletHotel: 'selectOutletPage.hotel',
  selectOutletOutlet: 'selectOutletPage.outlet',
}

export const DEVICECHECK_SERVICES = {
  sucuss: "sucuss",
  error: "error",
  possible: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  BLANK: "",
}
export const MODAL_INFO = {
  setModalData: 'setModalData.'
}

export const COMMOM_MESSAGES_SERVICES = {
  presentLoaderMessage: 'ServiceMessages.presentLoaderMessage',
  initloaderMessage: 'ServiceMessages.initloaderMessage',
  GotIt: 'ServiceMessages.GotIt',
  internetOffMessage: 'ServiceMessages.internetOffMessage',
  errorConnectionsHandlerActionMessage: 'ServiceMessages.errorConnectionsHandlerActionMessage',
  errorRedeemQRHandlerActionMessage: 'ServiceMessages.errorRedeemQRHandlerActionMessage',
  errorRedeemQRHandlerMessage: 'ServiceMessages.errorRedeemQRHandlerMessage',
  errorHandlerForRootAndroidMessage: 'ServiceMessages.errorHandlerForRootAndroidMessage',
  errorHandlerForRootIosMessage: 'ServiceMessages.errorHandlerForRootIosMessage',
  errorHandlerForDeviceCheckMessage: 'ServiceMessages.errorHandlerForDeviceCheckMessage',
  displayNetworkUpdateMessage: 'ServiceMessages.displayNetworkUpdateMessage',
  live_assistance: 'CommonServicePage.live_assistance',
  emailRegexMsgOnLogin: 'ServiceMessages.emaiRegexMsg',
  againInternetOffMessage: "Please check your Internet connection.",
  errorcancelledQRHandlerActionMessage: 'ServiceMessages.errorcancelledQRHandlerActionMessage',
  errorOutletQRHandlerActionMessage: 'ServiceMessages.errorOutletQRHandlerActionMessage',
  errorrAlreadyRedeemQRHandlerActionMessage: 'ServiceMessages.errorrAlreadyRedeemQRHandlerActionMessage'
}
export const OPEN_RESERVATION_PAGE = {
  OPEN_RESERVATION: 'Components.open_Reservation',
  confirmReservation: 'confirmReservation',
}
export const MONTHS = {
  january: "january",
  february: "february",
  march: "march",
  april: "april",
  may: "may",
  june: "june",
  july: "july",
  august: "august",
  september: "september",
  october: "october",
  november: "november",
  december: "december",
  startDate: 'startDate',
  endDate: 'endDate'
}

export const TLC_ERROR = {
  TLC664: 'TLC-664'
}

export const PDFVIEWERS_MODAL = {
  pdfURL: 'pdfURL',
  // iOSVesrionCheck: "13.0.0",
  iab_blank: '_blank',
  iabOption: 'location=no,toolbar=yes,clearsessioncache=yes,clearcache=yes'
}


export interface IdentifierExporterPayload {
  keys: Array<IdentifierExporterPayloadElement>;
}

export interface IdentifierExporterPayloadElement {
  identifier: string;
  identifierType: "outlet" | "certificate" | "cardbenefit" | "promotion" | "promotioncontent";
}

export interface ReservationPromocodeQueryString {
  promoCode: string,
  tx: 'Reservation'
}

export const OUTLET_MASTER_PAGE = {
  OUTLET_MASTER: 'Components.outletMaster',
  VIEW_OUTLET_MASTER: 'Components.viewOutletMaster',
  closedOutletWindow: "outletClose",
  openOutletWindow: "outletOpen",
  day: 'day',
  selectedAllDays: [0, 1, 2, 3, 4, 5, 6],
  COUNT_00: ':00',
  COUNT_ADD: ':',
  COUNT_TIME: '12:00',
  ionSelect: 'ion-select',
  selectIconInner: '.select-icon-inner',
  COUNT: 'COUNT',
  BLOCK: 'BLOCK',
  endDate: 'endDate',
  startDate: 'startDate'
}

export const ORDER_PAGE = {
  ORDER: 'Orders to be Booked',
}

export const TABLE_MASTER_PAGE = {
  TALBE_MASTER: 'Components.tableMaster',
}
export const TABLE_PAGE = {
  TALBE: 'Components.editTable',
}

export const OUTLET_SECTION_PAGE = {
  OUTLET_SECTION: 'Components.outletSection',
}

export const TAGS_PAGE = {
  TAGS: 'Components.addTags',
}

